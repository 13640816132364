<template>
  <div>
    <!-- Title -->
    <h1 v-t="'global.site'" />
    <!-- Subtitle -->
    <h2
      v-t="'login.subtitle'"
      :style="`color: ${$xms.theme.global.primary}`"
    />
    <!-- Form -->
    <v-form
      ref="form-login"
      class="my-8"
      @submit.prevent="onSubmit"
    >
      <label
        v-t="'login.email'"
        for="field-email"
        class="label-input"
      />
      <v-text-field
        id="field-email"
        v-model="email"
        :rules="[rules.required, rules.email]"
        hide-details="auto"
        color="primary"
        class="mb-4"
        name="email"
        outlined
        dense
      />
      <label
        v-t="'login.password'"
        for="field-password"
        class="label-input"
      />
      <v-text-field
        id="field-password"
        v-model="password"
        :rules="[rules.required]"
        :append-icon="show1 ? 'visibility_off' : 'visibility'"
        :type="show1 ? 'text' : 'password'"
        hide-details="auto"
        color="primary"
        name="password"
        class="mb-3"
        outlined
        dense
        @click:append="show1 = !show1"
      />
      <!-- Link to Forgot Password -->
      <!-- TODO: Temporarily because all project doesn't have a reset password in api/db -->
      <router-link
        v-if="this.$xms.config.resetPassword"
        v-t="'login.forgot_password'"
        to="forgot-password"
        class="d-inline-block mb-10 text-decoration-none"
      />
      <!-- Actions -->
      <v-container
        fluid
        pa-0
      >
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <v-checkbox
              v-model="rememberMe"
              :label="$t('login.stay_logged_in')"
              :hide-details="true"
              name="remember-me"
              color="primary"
              class="mt-0"
            />
          </v-col>
          <v-col
            class="pb-0"
            cols="12"
            sm="6"
          >
            <v-btn
              color="primary"
              type="submit"
              block
              @click="onSubmit"
            >
              {{ $t('ui.login') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import login from '../../mixins/login';

export default {
  name: 'FormLogin',
  mixins: [login],
  data () {
    return {
      email: '',
      password: '',
      rememberMe: true,
      show1: false,
    };
  },
  metaInfo () {
    return {
      title: `${this.$t('login.title')} | `,
    };
  },
  methods: {
    ...mapActions({
      login: 'auth/login',
    }),
    async onSubmit () {
      const { $http, email, password, rememberMe } = this;
      const validation = this.$refs['form-login'].validate();

      if (validation === true) {
        try {
          await this.login({ $http, email, password, rememberMe });
          this.$ga.event({
            eventCategory: 'core',
            eventAction: 'auth/login',
            eventLabel: email,
          });
          this.$reportSuccess({ message: 'dialogs.login_success' });
        } catch (e) {
          this.$reportError({ message: 'errors.login_error' });
        }
      }
    },
  },
};
</script>
